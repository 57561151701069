import React, { useState, useRef } from "react";
import axios from "axios";
import { server, RECAPTCHA_KEY } from "../env";
import Loader from "../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState("");
  const recaptchaRef = useRef(null);

  const register = async (e) => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    setLoader(<Loader />);
    const data = {
      username: e.target.name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      captchaToken,
    };

    await axios
      .post(server + "/api/v1/auth/register", data)
      .then((res) => {
        setLoader("");
        toast.success("Account created successfully, please login");
        setTimeout(() => {
          navigate("/login");
        }, 2500);
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };
  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div
        className="nk-main "
        style={{
          background:
            "linear-gradient(0deg, rgba(29,0,5,1) 0%, rgba(122,0,22,1) 100%)",
        }}
      >
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img
                    className="logo-light logo-img logo-img-lg"
                    src="/assets/logo.svg"
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div className="card">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Sign-Up</h4>
                      <div className="nk-block-des"></div>
                    </div>
                  </div>
                  <form onSubmit={register}>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">
                          Name
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="default-01"
                        name="name"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">
                          Email
                        </label>
                      </div>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="default-01"
                        name="email"
                        placeholder="Enter your email address "
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <input
                          type="password"
                          className="form-control form-control-lg"
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        className="btn btn-lg btn-primary btn-block"
                        type="submit"
                      >
                        Sign Up {loader}
                      </button>
                    </div>
                  </form>
                  <div className="form-note-s2 text-center pt-4">
                    {" "}
                    Already have an account? <Link to="/">Login</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>
      <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
      {/* main @e */}
    </div>
  );
};

export default Register;
