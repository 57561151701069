import React from "react";
import { useRoutes } from "react-router-dom";
import CreateOrder from "../source/CreateOrder";
import Dashboard from "../source/Dashboard";
import Deposits from "../source/Deposits";
import CsvOrders from "../source/CsvOrders";
import Address from "../source/Address";
import Sku from "../source/Sku";
import ResellerAPI from "../source/reseller/ResellerAPI";

const DashboardRoutes = (props) => {
  return useRoutes([
    { path: "", element: <Dashboard sidebarRef={props.sidebarRef} /> },
    {
      path: "/createorders",
      element: <CreateOrder sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/address",
      element: <Address sidebarRef={props.sidebarRef} />,
    },

    {
      path: "/sku",
      element: <Sku sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/deposits",
      element: <Deposits sidebarRef={props.sidebarRef} />,
    },

    {
      path: "/csv-orders",
      element: <CsvOrders sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/api-docs",
      element: <ResellerAPI sidebarRef={props.sidebarRef} />,
    },
  ]);
};

export default DashboardRoutes;
