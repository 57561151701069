function convertTxtToJson(textFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event.target.result;
      const rows = text.split("\n");
      // Assuming the file is tab-separated, we will split by "\t"
      const headers = rows[0].split("\t");
      const data = rows.slice(1).map((row) => {
        const values = row.split("\t");
        return headers.reduce((obj, header, i) => {
          obj[header] = values[i];
          return obj;
        }, {});
      });

      resolve(data);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsText(textFile);
  });
}

module.exports = {
  convertTxtToJson,
};
