import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";

const Sku = (props) => {
  const [loader, setLoader] = useState("");
  const [skus, setSkus] = useState([]);
  const [labelTypes, setLabelTypes] = useState([]);
  const [skuData, setSkuData] = useState({});

  const readLabelTypes = async () => {
    await axios
      .get(server + "/api/v1/labeltype/read", config)
      .then((res) => {
        setLabelTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readSkus = async () => {
    await axios
      .get(server + "/api/v1/sku/read", config)
      .then((res) => {
        setSkus(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createSku = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      name: e.target.name.value,
      SKU: e.target.SKU.value,
      weight: e.target.weight.value,
      length: e.target.length.value,
      width: e.target.width.value,
      height: e.target.height.value,
      labelType: e.target.labelType.value,
      template: e.target.template.value,
    };

    await axios
      .post(server + "/api/v1/sku/create", params, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readSkus();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const updateSku = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      name: e.target.name.value,
      SKU: e.target.SKU.value,
      weight: e.target.weight.value,
      length: e.target.length.value,
      width: e.target.width.value,
      height: e.target.height.value,
      labelType: e.target.labelType.value,
      template: e.target.template.value,
    };

    await axios
      .put(server + "/api/v1/sku/update/" + skuData._id, params, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readSkus();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const deleteSku = async () => {
    setLoader(<Loader />);
    await axios
      .delete(server + "/api/v1/sku/delete/" + skuData._id, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readSkus();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    readSkus();
    readLabelTypes();
  }, []);

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-end mb-2">
                    <button
                      className="btn btn-sm btn-primary px-2 py-1"
                      data-target="#add"
                      data-toggle="modal"
                    >
                      Add SKU
                    </button>
                  </div>
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">SKU</th>
                        <th scope="col">Weight</th>
                        <th scope="col">Length</th>
                        <th scope="col">Width</th>
                        <th scope="col">Height</th>
                        <th scope="col">LabelType</th>
                        <th scope="col">Template</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {skus.map((sku, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{sku.name}</td>
                          <td>{sku.SKU}</td>
                          <td>{sku.weight}</td>
                          <td>{sku.length}</td>
                          <td>{sku.width}</td>
                          <td>{sku.height}</td>
                          <td>{sku.labelType?.name}</td>
                          <td>{sku.template}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary px-2 py-1 mr-1"
                              onClick={() => {
                                setSkuData(sku);
                              }}
                              data-target="#edit"
                              data-toggle="modal"
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-sm btn-danger px-2 py-1"
                              onClick={() => {
                                setSkuData(sku);
                              }}
                              data-target="#delete"
                              data-toggle="modal"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create sku modal */}
      <div
        id="add"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add SKU
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={createSku}>
                <div className="form-group mb-3">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter Name"
                    name="name"
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="SKU">SKU</label>
                  <input
                    type="text"
                    className="form-control"
                    id="SKU"
                    placeholder="Enter SKU"
                    name="SKU"
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="weight">Weight (LBS)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="weight"
                    placeholder="Enter Weight"
                    name="weight"
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="length">Length</label>
                  <input
                    type="text"
                    className="form-control"
                    id="length"
                    placeholder="Enter Length"
                    name="length"
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="width">Width</label>
                  <input
                    type="text"
                    className="form-control"
                    id="width"
                    placeholder="Enter Width"
                    name="width"
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="height">Height</label>
                  <input
                    type="text"
                    className="form-control"
                    id="height"
                    placeholder="Enter Height"
                    name="height"
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="labelType">Label Type</label>
                  <select
                    className="form-control"
                    id="labelType"
                    name="labelType"
                  >
                    <option>Select Label Type</option>
                    {labelTypes.map((labelType, index) => (
                      <option key={index} value={labelType._id}>
                        {labelType.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="template">Template</label>
                  <select
                    className="form-control"
                    id="template"
                    name="template"
                  >
                    <option>Select Template</option>
                    <option value="normal">Pitney Bowes</option>
                    <option value="indica">Indica</option>
                    <option value="stamps">Stamps</option>
                    <option value="evs">EVS</option>
                  </select>
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Create {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* edit sku modal */}
      <div
        id="edit"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Update SKU
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={updateSku}>
                <div className="form-group mb-3">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter Name"
                    name="name"
                    value={skuData.name}
                    onChange={(e) => {
                      setSkuData({ ...skuData, name: e.target.value });
                    }}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="SKU">SKU</label>
                  <input
                    type="text"
                    className="form-control"
                    id="SKU"
                    placeholder="Enter SKU"
                    name="SKU"
                    value={skuData.SKU}
                    onChange={(e) => {
                      setSkuData({ ...skuData, SKU: e.target.value });
                    }}
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="weight">Weight (LBS)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="weight"
                    placeholder="Enter Weight"
                    name="weight"
                    value={skuData.weight}
                    onChange={(e) => {
                      setSkuData({ ...skuData, weight: e.target.value });
                    }}
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="length">Length</label>
                  <input
                    type="text"
                    className="form-control"
                    id="length"
                    placeholder="Enter Length"
                    name="length"
                    value={skuData.length}
                    onChange={(e) => {
                      setSkuData({ ...skuData, length: e.target.value });
                    }}
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="width">Width</label>
                  <input
                    type="text"
                    className="form-control"
                    id="width"
                    placeholder="Enter Width"
                    name="width"
                    value={skuData.width}
                    onChange={(e) => {
                      setSkuData({ ...skuData, width: e.target.value });
                    }}
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="height">Height</label>
                  <input
                    type="text"
                    className="form-control"
                    id="height"
                    placeholder="Enter Height"
                    name="height"
                    value={skuData.height}
                    onChange={(e) => {
                      setSkuData({ ...skuData, height: e.target.value });
                    }}
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="labelType">Label Type</label>
                  <select
                    className="form-control"
                    id="labelType"
                    name="labelType"
                    value={skuData.labelType?._id}
                    onChange={(e) => {
                      setSkuData({
                        ...skuData,
                        labelType: {
                          _id: e.target.value,
                        },
                      });
                    }}
                  >
                    <option>Select Label Type</option>
                    {labelTypes.map((labelType, index) => (
                      <option key={index} value={labelType._id}>
                        {labelType.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="template">Template</label>
                  <select
                    className="form-control"
                    id="template"
                    name="template"
                    value={skuData.template}
                    onChange={(e) => {
                      setSkuData({ ...skuData, template: e.target.value });
                    }}
                  >
                    <option>Select Template</option>
                    <option value="normal">Pitney Bowes</option>
                    <option value="indica">Indica</option>
                    <option value="stamps">Stamps</option>
                    <option value="evs">EVS</option>
                  </select>
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* delete sku modal */}

      <div
        id="delete"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Delete SKU
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to delete <strong>{skuData.name}</strong>?
              </p>

              <div className="d-flex mt-2 justify-content-end ">
                <button
                  type="button"
                  className="btn btn-white me-3"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button className="btn btn-primary" onClick={() => deleteSku()}>
                  Delete {loader}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Sku;
